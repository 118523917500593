(function() {
  var __commonJS = function(callback, module) {
    return function() {
      if (!module) {
        module = {exports: {}};
        callback(module.exports, module);
      }
      return module.exports;
    };
  };

  // node_modules/ext/global-this/is-implemented.js
  var require_is_implemented = __commonJS(function(exports, module) {
    "use strict";
    module.exports = function() {
      if (typeof globalThis !== "object")
        return false;
      if (!globalThis)
        return false;
      return globalThis.Array === Array;
    };
  });

  // node_modules/ext/global-this/implementation.js
  var require_implementation = __commonJS(function(exports, module) {
    var naiveFallback = function() {
      if (typeof self === "object" && self)
        return self;
      if (typeof window === "object" && window)
        return window;
      throw new Error("Unable to resolve global `this`");
    };
    module.exports = function() {
      if (this)
        return this;
      try {
        Object.defineProperty(Object.prototype, "__global__", {
          get: function() {
            return this;
          },
          configurable: true
        });
      } catch (error) {
        return naiveFallback();
      }
      try {
        if (!__global__)
          return naiveFallback();
        return __global__;
      } finally {
        delete Object.prototype.__global__;
      }
    }();
  });

  // node_modules/ext/global-this/index.js
  var require_global_this = __commonJS(function(exports, module) {
    "use strict";
    module.exports = require_is_implemented()() ? globalThis : require_implementation();
  });

  // node_modules/es6-symbol/is-implemented.js
  var require_is_implemented2 = __commonJS(function(exports, module) {
    "use strict";
    var global = require_global_this();
    var validTypes = {object: true, symbol: true};
    module.exports = function() {
      var Symbol = global.Symbol;
      var symbol;
      if (typeof Symbol !== "function")
        return false;
      symbol = Symbol("test symbol");
      try {
        String(symbol);
      } catch (e) {
        return false;
      }
      if (!validTypes[typeof Symbol.iterator])
        return false;
      if (!validTypes[typeof Symbol.toPrimitive])
        return false;
      if (!validTypes[typeof Symbol.toStringTag])
        return false;
      return true;
    };
  });

  // node_modules/type/value/is.js
  var require_is = __commonJS(function(exports, module) {
    "use strict";
    var _undefined = void 0;
    module.exports = function(value) {
      return value !== _undefined && value !== null;
    };
  });

  // node_modules/type/object/is.js
  var require_is2 = __commonJS(function(exports, module) {
    "use strict";
    var isValue = require_is();
    var possibleTypes = {object: true, function: true, undefined: true};
    module.exports = function(value) {
      if (!isValue(value))
        return false;
      return hasOwnProperty.call(possibleTypes, typeof value);
    };
  });

  // node_modules/type/prototype/is.js
  var require_is3 = __commonJS(function(exports, module) {
    "use strict";
    var isObject = require_is2();
    module.exports = function(value) {
      if (!isObject(value))
        return false;
      try {
        if (!value.constructor)
          return false;
        return value.constructor.prototype === value;
      } catch (error) {
        return false;
      }
    };
  });

  // node_modules/type/function/is.js
  var require_is4 = __commonJS(function(exports, module) {
    "use strict";
    var isPrototype = require_is3();
    module.exports = function(value) {
      if (typeof value !== "function")
        return false;
      if (!hasOwnProperty.call(value, "length"))
        return false;
      try {
        if (typeof value.length !== "number")
          return false;
        if (typeof value.call !== "function")
          return false;
        if (typeof value.apply !== "function")
          return false;
      } catch (error) {
        return false;
      }
      return !isPrototype(value);
    };
  });

  // node_modules/type/plain-function/is.js
  var require_is5 = __commonJS(function(exports, module) {
    "use strict";
    var isFunction = require_is4();
    var classRe = /^\s*class[\s{/}]/;
    var functionToString = Function.prototype.toString;
    module.exports = function(value) {
      if (!isFunction(value))
        return false;
      if (classRe.test(functionToString.call(value)))
        return false;
      return true;
    };
  });

  // node_modules/es5-ext/object/assign/is-implemented.js
  var require_is_implemented3 = __commonJS(function(exports, module) {
    "use strict";
    module.exports = function() {
      var assign = Object.assign, obj;
      if (typeof assign !== "function")
        return false;
      obj = {foo: "raz"};
      assign(obj, {bar: "dwa"}, {trzy: "trzy"});
      return obj.foo + obj.bar + obj.trzy === "razdwatrzy";
    };
  });

  // node_modules/es5-ext/object/keys/is-implemented.js
  var require_is_implemented4 = __commonJS(function(exports, module) {
    "use strict";
    module.exports = function() {
      try {
        Object.keys("primitive");
        return true;
      } catch (e) {
        return false;
      }
    };
  });

  // node_modules/es5-ext/function/noop.js
  var require_noop = __commonJS(function(exports, module) {
    "use strict";
    module.exports = function() {
    };
  });

  // node_modules/es5-ext/object/is-value.js
  var require_is_value = __commonJS(function(exports, module) {
    "use strict";
    var _undefined = require_noop()();
    module.exports = function(val) {
      return val !== _undefined && val !== null;
    };
  });

  // node_modules/es5-ext/object/keys/shim.js
  var require_shim = __commonJS(function(exports, module) {
    "use strict";
    var isValue = require_is_value();
    var keys = Object.keys;
    module.exports = function(object) {
      return keys(isValue(object) ? Object(object) : object);
    };
  });

  // node_modules/es5-ext/object/keys/index.js
  var require_keys = __commonJS(function(exports, module) {
    "use strict";
    module.exports = require_is_implemented4()() ? Object.keys : require_shim();
  });

  // node_modules/es5-ext/object/valid-value.js
  var require_valid_value = __commonJS(function(exports, module) {
    "use strict";
    var isValue = require_is_value();
    module.exports = function(value) {
      if (!isValue(value))
        throw new TypeError("Cannot use null or undefined");
      return value;
    };
  });

  // node_modules/es5-ext/object/assign/shim.js
  var require_shim2 = __commonJS(function(exports, module) {
    "use strict";
    var keys = require_keys();
    var value = require_valid_value();
    var max = Math.max;
    module.exports = function(dest, src) {
      var error, i, length = max(arguments.length, 2), assign;
      dest = Object(value(dest));
      assign = function(key) {
        try {
          dest[key] = src[key];
        } catch (e) {
          if (!error)
            error = e;
        }
      };
      for (i = 1; i < length; ++i) {
        src = arguments[i];
        keys(src).forEach(assign);
      }
      if (error !== void 0)
        throw error;
      return dest;
    };
  });

  // node_modules/es5-ext/object/assign/index.js
  var require_assign = __commonJS(function(exports, module) {
    "use strict";
    module.exports = require_is_implemented3()() ? Object.assign : require_shim2();
  });

  // node_modules/es5-ext/object/normalize-options.js
  var require_normalize_options = __commonJS(function(exports, module) {
    "use strict";
    var isValue = require_is_value();
    var forEach = Array.prototype.forEach;
    var create = Object.create;
    var process = function(src, obj) {
      var key;
      for (key in src)
        obj[key] = src[key];
    };
    module.exports = function(opts1) {
      var result = create(null);
      forEach.call(arguments, function(options) {
        if (!isValue(options))
          return;
        process(Object(options), result);
      });
      return result;
    };
  });

  // node_modules/es5-ext/string/#/contains/is-implemented.js
  var require_is_implemented5 = __commonJS(function(exports, module) {
    "use strict";
    var str = "razdwatrzy";
    module.exports = function() {
      if (typeof str.contains !== "function")
        return false;
      return str.contains("dwa") === true && str.contains("foo") === false;
    };
  });

  // node_modules/es5-ext/string/#/contains/shim.js
  var require_shim3 = __commonJS(function(exports, module) {
    "use strict";
    var indexOf = String.prototype.indexOf;
    module.exports = function(searchString) {
      return indexOf.call(this, searchString, arguments[1]) > -1;
    };
  });

  // node_modules/es5-ext/string/#/contains/index.js
  var require_contains = __commonJS(function(exports, module) {
    "use strict";
    module.exports = require_is_implemented5()() ? String.prototype.contains : require_shim3();
  });

  // node_modules/d/index.js
  var require_d = __commonJS(function(exports, module) {
    "use strict";
    var isValue = require_is();
    var isPlainFunction = require_is5();
    var assign = require_assign();
    var normalizeOpts = require_normalize_options();
    var contains = require_contains();
    var d = module.exports = function(dscr, value) {
      var c, e, w, options, desc;
      if (arguments.length < 2 || typeof dscr !== "string") {
        options = value;
        value = dscr;
        dscr = null;
      } else {
        options = arguments[2];
      }
      if (isValue(dscr)) {
        c = contains.call(dscr, "c");
        e = contains.call(dscr, "e");
        w = contains.call(dscr, "w");
      } else {
        c = w = true;
        e = false;
      }
      desc = {value: value, configurable: c, enumerable: e, writable: w};
      return !options ? desc : assign(normalizeOpts(options), desc);
    };
    d.gs = function(dscr, get, set) {
      var c, e, options, desc;
      if (typeof dscr !== "string") {
        options = set;
        set = get;
        get = dscr;
        dscr = null;
      } else {
        options = arguments[3];
      }
      if (!isValue(get)) {
        get = void 0;
      } else if (!isPlainFunction(get)) {
        options = get;
        get = set = void 0;
      } else if (!isValue(set)) {
        set = void 0;
      } else if (!isPlainFunction(set)) {
        options = set;
        set = void 0;
      }
      if (isValue(dscr)) {
        c = contains.call(dscr, "c");
        e = contains.call(dscr, "e");
      } else {
        c = true;
        e = false;
      }
      desc = {get: get, set: set, configurable: c, enumerable: e};
      return !options ? desc : assign(normalizeOpts(options), desc);
    };
  });

  // node_modules/es6-symbol/is-symbol.js
  var require_is_symbol = __commonJS(function(exports, module) {
    "use strict";
    module.exports = function(value) {
      if (!value)
        return false;
      if (typeof value === "symbol")
        return true;
      if (!value.constructor)
        return false;
      if (value.constructor.name !== "Symbol")
        return false;
      return value[value.constructor.toStringTag] === "Symbol";
    };
  });

  // node_modules/es6-symbol/validate-symbol.js
  var require_validate_symbol = __commonJS(function(exports, module) {
    "use strict";
    var isSymbol = require_is_symbol();
    module.exports = function(value) {
      if (!isSymbol(value))
        throw new TypeError(value + " is not a symbol");
      return value;
    };
  });

  // node_modules/es6-symbol/lib/private/generate-name.js
  var require_generate_name = __commonJS(function(exports, module) {
    "use strict";
    var d = require_d();
    var create = Object.create;
    var defineProperty = Object.defineProperty;
    var objPrototype = Object.prototype;
    var created = create(null);
    module.exports = function(desc) {
      var postfix = 0, name, ie11BugWorkaround;
      while (created[desc + (postfix || "")])
        ++postfix;
      desc += postfix || "";
      created[desc] = true;
      name = "@@" + desc;
      defineProperty(objPrototype, name, d.gs(null, function(value) {
        if (ie11BugWorkaround)
          return;
        ie11BugWorkaround = true;
        defineProperty(this, name, d(value));
        ie11BugWorkaround = false;
      }));
      return name;
    };
  });

  // node_modules/es6-symbol/lib/private/setup/standard-symbols.js
  var require_standard_symbols = __commonJS(function(exports, module) {
    "use strict";
    var d = require_d();
    var NativeSymbol = require_global_this().Symbol;
    module.exports = function(SymbolPolyfill) {
      return Object.defineProperties(SymbolPolyfill, {
        hasInstance: d("", NativeSymbol && NativeSymbol.hasInstance || SymbolPolyfill("hasInstance")),
        isConcatSpreadable: d("", NativeSymbol && NativeSymbol.isConcatSpreadable || SymbolPolyfill("isConcatSpreadable")),
        iterator: d("", NativeSymbol && NativeSymbol.iterator || SymbolPolyfill("iterator")),
        match: d("", NativeSymbol && NativeSymbol.match || SymbolPolyfill("match")),
        replace: d("", NativeSymbol && NativeSymbol.replace || SymbolPolyfill("replace")),
        search: d("", NativeSymbol && NativeSymbol.search || SymbolPolyfill("search")),
        species: d("", NativeSymbol && NativeSymbol.species || SymbolPolyfill("species")),
        split: d("", NativeSymbol && NativeSymbol.split || SymbolPolyfill("split")),
        toPrimitive: d("", NativeSymbol && NativeSymbol.toPrimitive || SymbolPolyfill("toPrimitive")),
        toStringTag: d("", NativeSymbol && NativeSymbol.toStringTag || SymbolPolyfill("toStringTag")),
        unscopables: d("", NativeSymbol && NativeSymbol.unscopables || SymbolPolyfill("unscopables"))
      });
    };
  });

  // node_modules/es6-symbol/lib/private/setup/symbol-registry.js
  var require_symbol_registry = __commonJS(function(exports, module) {
    "use strict";
    var d = require_d();
    var validateSymbol = require_validate_symbol();
    var registry = Object.create(null);
    module.exports = function(SymbolPolyfill) {
      return Object.defineProperties(SymbolPolyfill, {
        for: d(function(key) {
          if (registry[key])
            return registry[key];
          return registry[key] = SymbolPolyfill(String(key));
        }),
        keyFor: d(function(symbol) {
          var key;
          validateSymbol(symbol);
          for (key in registry) {
            if (registry[key] === symbol)
              return key;
          }
          return void 0;
        })
      });
    };
  });

  // node_modules/es6-symbol/polyfill.js
  var require_polyfill = __commonJS(function(exports, module) {
    "use strict";
    var d = require_d();
    var validateSymbol = require_validate_symbol();
    var NativeSymbol = require_global_this().Symbol;
    var generateName = require_generate_name();
    var setupStandardSymbols = require_standard_symbols();
    var setupSymbolRegistry = require_symbol_registry();
    var create = Object.create;
    var defineProperties = Object.defineProperties;
    var defineProperty = Object.defineProperty;
    var SymbolPolyfill;
    var HiddenSymbol;
    var isNativeSafe;
    if (typeof NativeSymbol === "function") {
      try {
        String(NativeSymbol());
        isNativeSafe = true;
      } catch (ignore) {
      }
    } else {
      NativeSymbol = null;
    }
    HiddenSymbol = function Symbol(description) {
      if (this instanceof HiddenSymbol)
        throw new TypeError("Symbol is not a constructor");
      return SymbolPolyfill(description);
    };
    module.exports = SymbolPolyfill = function Symbol(description) {
      var symbol;
      if (this instanceof Symbol)
        throw new TypeError("Symbol is not a constructor");
      if (isNativeSafe)
        return NativeSymbol(description);
      symbol = create(HiddenSymbol.prototype);
      description = description === void 0 ? "" : String(description);
      return defineProperties(symbol, {
        __description__: d("", description),
        __name__: d("", generateName(description))
      });
    };
    setupStandardSymbols(SymbolPolyfill);
    setupSymbolRegistry(SymbolPolyfill);
    defineProperties(HiddenSymbol.prototype, {
      constructor: d(SymbolPolyfill),
      toString: d("", function() {
        return this.__name__;
      })
    });
    defineProperties(SymbolPolyfill.prototype, {
      toString: d(function() {
        return "Symbol (" + validateSymbol(this).__description__ + ")";
      }),
      valueOf: d(function() {
        return validateSymbol(this);
      })
    });
    defineProperty(SymbolPolyfill.prototype, SymbolPolyfill.toPrimitive, d("", function() {
      var symbol = validateSymbol(this);
      if (typeof symbol === "symbol")
        return symbol;
      return symbol.toString();
    }));
    defineProperty(SymbolPolyfill.prototype, SymbolPolyfill.toStringTag, d("c", "Symbol"));
    defineProperty(HiddenSymbol.prototype, SymbolPolyfill.toStringTag, d("c", SymbolPolyfill.prototype[SymbolPolyfill.toStringTag]));
    defineProperty(HiddenSymbol.prototype, SymbolPolyfill.toPrimitive, d("c", SymbolPolyfill.prototype[SymbolPolyfill.toPrimitive]));
  });

  // node_modules/es6-symbol/implement.js
  "use strict";
  if (!require_is_implemented2()()) {
    Object.defineProperty(require_global_this(), "Symbol", {
      value: require_polyfill(),
      configurable: true,
      enumerable: false,
      writable: true
    });
  }
})();
